import React, { useContext, useEffect, useRef } from "react";
import { store } from "../../../Store";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";

import { ModoPWAContext } from "../../../../contexts/ModoPwaContext";
import useIsMobile from "../../../../js/utilities/useIsMobile";
import { useLogout } from "../../../../hooks/useLogout";
import { useIndividualHeaderDropdown } from "../../../../hooks/header/useIndividualHeaderDropdown";
import { useNetworkStatus } from "../../../../hooks/use-network-status";
import { DropdownNotificationButton } from "../../components/notification-button";
import { useAccessibility } from "../../../../hooks/useAccessibility";

/**
 * Componente con las opciones favoritos, mi panel y cerrar sesión para cuenta individual
 * @param {object} textosInterfaz - contiene los textos de interfaz
 * @returns {JSX.Element} DropdownCuentaIndividual
 */
export const DropdownCuentaIndividual = ({ textosInterfaz }) => {
    const pwaState = useContext(ModoPWAContext);
    const globalState = useContext(store);
    const { state } = globalState;
    const location = useLocation();
    const logoutHandler = useLogout();
    const isMobile = useIsMobile();
    const networkStatus = useNetworkStatus();

    const accessibilityButtonRef = useRef(null);
    const accessibility = useAccessibility();
    const ref = useRef(null);
    const showADropdown = useIndividualHeaderDropdown();
    const visible = showADropdown?.state?.visible;
    const triggerButton = globalState?.state?.individualHeaderDropdown?.triggerButtonRef;

    /*cierra el dropdown al hacer scroll al inicio de la página */
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY >= 1 && window.scrollY < 10) {
                showADropdown.setVisible(false);
            }
        });
    }, [visible]);

    /**Función que permite cerrar el dropdown dando click fuera de él
    * @param {event} event 
    */
    const handleOutsideClick = (event) => {
        if (triggerButton?.current?.contains(event.target)) return;
        if (ref.current && !ref.current.contains(event.target)) {
            showADropdown.setVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [triggerButton]);

    /*Asigna el botón que abre las opciones de accesibilidad */
    useEffect(() => {
        if (accessibilityButtonRef.current) {
            globalState.dispatch({
                type: "CHANGE",
                path: "accessibility.triggerButton",
                value: accessibilityButtonRef,
            });
        }
    }, [accessibilityButtonRef]);

    return (
        <>
            {(state.userType === 'Cliente' || state.userType === "Subcliente") &&
                <div className={`${isMobile ? 'dropdownMenuResponsive' : 'dropdownMenu'} ${visible ? 'activeMode' : 'inactiveMode'}`}>
                    <ul ref={ref}>
                        <li className={`${visible ? 'activeMode' : 'inactiveMode'}`}>
                            <Link
                                to="/favoritos"
                                onClick={() => showADropdown.setVisible(false)}
                                className="link-principal d-flex justify-content-center align-items-center focusable-red"
                            >
                                <Icon icon="material-symbols:favorite" width={20} className="mr-3" />
                                {textosInterfaz?.favoritos || 'Favoritos'}
                            </Link>
                        </li>

                        {state.userType === 'Cliente' &&
                            <li className={`${visible ? 'activeMode' : 'inactiveMode'}`}>
                                <Link
                                    to="/mi-panel/mi-cuenta"
                                    onClick={() => showADropdown.setVisible(false)}
                                    className="link-principal d-flex justify-content-center align-items-center focusable-red"
                                >
                                    <Icon icon="mage:dashboard-chart" width={20} className="mr-3" />
                                    {textosInterfaz?.panel || 'Mi panel'}
                                </Link>
                            </li>
                        }

                        {isMobile &&
                            <>
                                <hr className={`${visible ? 'activeMode' : 'inactiveMode'}`} />

                                <DropdownNotificationButton
                                    visibleDropdown={visible}
                                    onClick={() => showADropdown.setVisible(false)}
                                    translations={textosInterfaz} />

                                <li className={`${visible ? 'activeMode' : 'inactiveMode'}`}>
                                    <button
                                        ref={accessibilityButtonRef}
                                        className="link-secundario focusable-red mx-auto w-100"
                                        style={{ fontWeight: 500 }}
                                        onClick={() => {
                                            accessibility.setVisible(true);
                                            showADropdown.setVisible(false)
                                        }}
                                    >
                                        {textosInterfaz?.accesibilidad || 'Accesibilidad'}
                                    </button>
                                </li>
                                <hr className={`${visible ? 'activeMode' : 'inactiveMode'}`} />
                            </>

                        }

                        {(!pwaState.appInstalada && networkStatus.online) &&
                            <li className={`${visible ? 'activeMode' : 'inactiveMode'}`}>
                                <button
                                    tabIndex={0}
                                    className="link-secundario w-100 d-flex justify-content-center align-items-center focusable-red"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        logoutHandler.logout();
                                        showADropdown.setVisible(false)
                                    }}
                                >

                                    {logoutHandler?.loading ?
                                        <i className="fas fa-spinner fa-pulse text-center"></i>
                                        :
                                        <>
                                            {textosInterfaz?.cerrar_sesion || 'Cerrar sesión'}
                                        </>
                                    }
                                </button>
                            </li>
                        }
                    </ul>
                </div>
            }
        </>


    )
}