
import React, { useContext } from "react";
import { useNotificationInboxVisibility } from "../../../notifications/hooks/use-notification-inbox-visibility";
import { NotificationIcon } from "../../../notifications/components/notification-icon";
import { store } from "../../Store";
import { AccesibilidadContext, TemasDisponibles } from "../../../contexts/AccesibilidadContext";

export const NotificationButton = () => {
    const globalState = useContext(store);
    const hasNewNotifications = globalState?.state?.notifications?.newNotifications;
    const notificationInboxVisibility = useNotificationInboxVisibility();
    const isAuth = globalState?.state?.sesionActiva === "SI";
    const accesibility = useContext(AccesibilidadContext);
    const isClassicTheme = accesibility.temaActual === TemasDisponibles.clasico.id;
    const isIndividualUser = globalState?.state?.cantidadLibros <= 40 && globalState?.state?.cantidadLibros >= 0;
    const color = isAuth & !isIndividualUser
		? isClassicTheme
			? globalState?.state.datosHeader?.colorLetra !== null
				? globalState?.state.datosHeader?.colorLetra
				: "var(--onsecondary-color)"
			: "var(--primary-inverse)"
		: "";

    const handleVisibility = () => {
        if (notificationInboxVisibility.isVisible) {
            notificationInboxVisibility.hide();
        }
        else {
            notificationInboxVisibility.show();
        }
    }

    return <li className=" btn-notification mx-1">
        <button className="notification-button focusable-red w-100 h-100 px-2" style={{color}} data-makemake-notification-button={true} onClick={() => {
            handleVisibility();
        }}>
            <NotificationIcon newNotification={hasNewNotifications} circleOffset={{ x: '50%', y: '25%' }} />
        </button>
    </li>
}

export const DropdownNotificationButton = ({ visibleDropdown, onClick, translations }) => {
    const globalState = useContext(store);
    const hasNewNotifications = globalState?.state?.notifications?.newNotifications;
    const notificationInboxVisibility = useNotificationInboxVisibility();

    const handleVisibility = () => {
        if (notificationInboxVisibility.isVisible) {
            notificationInboxVisibility.hide();
        }
        else {
            notificationInboxVisibility.show();
        }
    }

    return <li className={`${visibleDropdown ? 'activeMode' : 'inactiveMode'}`}>
        <button
            className="link-secundario d-flex justify-content-center align-items-center mx-auto focusable-red"
            style={{fontWeight: 500}}
            onClick={() => {
                onClick && onClick();
                handleVisibility();
            }}
        >
            {/* <div className="notification-button mx-2">
                <NotificationIcon newNotification={hasNewNotifications} circleOffset={{ x: '50%', y: '0%' }} />
            </div> */}
            {translations?.notificaciones || 'Notificaciones'}
        </button>
    </li>

}
export const MobileBottomNavBarNotificationButton = ({ onClick, translations }) => {
    const globalState = useContext(store);
    const hasNewNotifications = globalState?.state?.notifications?.newNotifications;
    const notificationInboxVisibility = useNotificationInboxVisibility();

    const handleVisibility = () => {
        if (notificationInboxVisibility.isVisible) {
            notificationInboxVisibility.hide();
        }
        else {
            notificationInboxVisibility.show();
        }
    }

    return <li className="col-3 px-0">
        <button
            className={`notification-button btn-option-menu focusable-red`}
            data-makemake-notification-button={true}
            onClick={() => {
                onClick && onClick();
                handleVisibility();
            }}
        >
            <NotificationIcon newNotification={hasNewNotifications} width={30} circleOffset={{ x: '51%', y: '17%' }} />
            <span>{translations?.notificaciones || 'Notificaciones'}</span>
        </button>
    </li>

}
