import React, { useState, useEffect, useContext, useRef } from "react";
import { store, ASSETS_URL } from "../../Store";
import { FocusScope } from "react-aria";

import { Icon } from "@iconify/react/dist/iconify.js";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import useIsMobile from "../../../js/utilities/useIsMobile";
import { useGeneralHeaderDropdown } from "../../../hooks/header/useGeneralHeaderDropdown";

import { NavbarBrand } from "../components/NavbarBrand";
import { NavbarNav } from "./components/NavbarNav";
import { SkeletonHeader } from "./components/SkeletonHeader";
import { DropdownMiCuenta } from "./components/DropdownMiCuenta";
import { BookDownloadingPopUp } from "../../book-downloading-popup";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";
import { LangButton } from "../../language/lang-button";
import { useAdvancedSearch } from "../../../hooks/advanced-search/use-advanced-search";
import { AccesibilidadContext, TemasDisponibles } from "../../../contexts/AccesibilidadContext";

/**
 * Componente del header institucional
 * @returns {JSX.Element} - GeneralHeader
 */
export const GeneralHeader = () => {
  const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
  const textosInterfaz = useTranslations({ section: "header", lang: lang, array: true });

  const globalState = useContext(store);
  const { state } = globalState;
  const isMobile = useIsMobile();
  const isLogged = state.sesionActiva !== null && state.sesionActiva === "SI";
  const [isLoading, setIsLoading] = useState(true);

  const showADropdown = useGeneralHeaderDropdown();
  const dropdownButtonRef = useRef(null);

  const advancedSearch = useAdvancedSearch();
  const advancedSearchButtonResRef = useRef(null);
  const [renderizado, setRenderizado] = useState(0);

  const isAuth = globalState?.state?.sesionActiva === "SI";
  const isIndividualUser = globalState?.state?.cantidadLibros <= 40 && globalState?.state?.cantidadLibros >= 0;
  const accesibility = useContext(AccesibilidadContext);
  const isClassicTheme = accesibility.temaActual === TemasDisponibles.clasico.id;
  const color = isAuth & !isIndividualUser
    ? isClassicTheme
        ? globalState?.state.datosHeader?.colorLetra !== null
            ? globalState?.state.datosHeader?.colorLetra
            : "var(--onsecondary-color)"
        : "var(--primary-inverse)"
    : "var(--onsecondary-color)";

  /*Asigna el botón que abre las opciones de búsqueda */
  useEffect(() => {
    if (renderizado === 0) {
      setRenderizado(renderizado.current + 1);
      return;
    }
    if (advancedSearchButtonResRef.current && isMobile) {
      globalState.dispatch({
        type: "CHANGE",
        path: "advancedSearch.triggerButton",
        value: advancedSearchButtonResRef,
      });
    }
  }, [advancedSearchButtonResRef, renderizado, isMobile]);


  useEffect(() => {
    if (dropdownButtonRef.current) {
      globalState.dispatch({
        type: "CHANGE",
        path: "generalHeaderDropdown.triggerButtonRef",
        value: dropdownButtonRef,
      });
    }
  }, [dropdownButtonRef.current]);

  useEffect(() => {
    if (state?.datosHeader) {
      setIsLoading(false);
    }
  }, [state?.datosHeader]);

  return (
    <>
      {isLoading ?
        <SkeletonHeader />
        :
        <div className="general-header">
          <nav className="h-100 row mx-0">
            <div className="col-7  col-lg-6 px-0 d-flex" style={{height: 50 }}>
              <NavbarBrand textosInterfaz={textosInterfaz}/>
            </div>

            {!isMobile &&
              <div className="col-lg-6 px-2">
                <NavbarNav textosInterfaz={textosInterfaz} />
              </div>
            }

            {isMobile &&
              <div className="col-5 col-lg-6 px-0 d-flex justify-content-end">
                <div className=" d-flex align-items-center mx-2">
                  <button
                    ref={advancedSearchButtonResRef}
                    className="search-button"
                    onClick={() => { 
                      advancedSearch.setVisible(!advancedSearch?.state?.visible); }}
                  >
                    <Icon icon="fa:search" className="icon-search-button" style={{ color: color }} />
                  </button>
                </div>

                <div className=" d-flex align-items-center mx-2">
                  <LangButton />
                </div>

                <button
                  ref={dropdownButtonRef}
                  className={`btn-cuenta focusable-red`}
                  onClick={() => { 
                    showADropdown.setVisible(!showADropdown?.state?.visible)
                  }}
                >
                  <div
                    className="mr-sm-1 mr-lg-3 btn-avatar"
                    style={
                      { backgroundImage: `url('${isLogged ? `${ASSETS_URL}${state.datosHeader?.avatar}` : '/img/profile.png'}')` }
                    }
                  ></div>
                  <span className="d-none d-sm-block">{textosInterfaz?.mi_cuenta || 'Mi cuenta'}</span>
                  <i className="fas fa-chevron-down fa-sm ml-2 arrow d-none d-lg-block"></i>
                </button>
              </div>
            }

          </nav>

          {isMobile &&
            <DropdownMiCuenta textosInterfaz={textosInterfaz}/>
          }

          {
            state.descargando &&
            <FocusScope contain>
              <BookDownloadingPopUp />
            </FocusScope>
          }
        </div>
      }
    </>
  )
}